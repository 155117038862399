import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { QUERY_PARAM } from '@shared/constants';
import { PRODUCT_SEARCH_INPUT } from '@shared/constants/queryKeys';
import { useScreenWidth } from '@shared/hooks';
import { trimSearchInput } from '@shared/utils';

const useSearchQuery = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const isScreenMobile = useScreenWidth();

  const searchQueryParam = searchParams.get(QUERY_PARAM.SEARCH_QUERY)
    ? decodeURIComponent(searchParams.get(QUERY_PARAM.SEARCH_QUERY)!)
    : '';

  const { data: searchQueryTest } = useQuery<string>({
    queryKey: [PRODUCT_SEARCH_INPUT],
  });

  const [searchQuery, setSearchQuery] = useState(searchQueryParam);

  useEffect(() => {
    if (searchQueryTest) setSearchQuery(searchQueryTest);
  }, [searchQueryTest]);

  useEffect(() => {
    if (!searchQueryParam) {
      queryClient.removeQueries({ queryKey: [PRODUCT_SEARCH_INPUT] });
      setSearchQuery('');
    } else {
      queryClient.setQueryData([PRODUCT_SEARCH_INPUT], searchQueryParam);
    }
  }, [searchQueryParam, queryClient]);

  const handleSearch = () => {
    const parsedSearchQuery = trimSearchInput(searchQuery);

    if (pathname !== '/') {
      router.push(`/?${QUERY_PARAM.SEARCH_QUERY}=${parsedSearchQuery}`);
    }

    const targetElement = document.getElementById(
      isScreenMobile ? 'browse-products' : 'products-section'
    );
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }

    queryClient.setQueryData([PRODUCT_SEARCH_INPUT], parsedSearchQuery);
  };

  return {
    searchQuery,
    setSearchQuery,
    handleSearch,
  };
};

export default useSearchQuery;
