import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

import { SortDirection } from '@interfaces/queryParams';
import { Updater } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

interface IQueryParamProps {
  queryKey: string;
  queryValue: string;
  shallow?: boolean;
  deleteOnKeyChange?: boolean;
}

interface IUseTableManagement {
  defaultPage?: number;
  defaultLimit?: number;
  defaultSortBy?: string;
  defaultSortDirection?: SortDirection;
}

const useTableManagement = ({
  defaultPage = 1,
  defaultLimit = 10,
  defaultSortBy = '',
  defaultSortDirection = SortDirection.DESC,
}: IUseTableManagement = {}) => {
  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();

  const [pagination, setPagination] = useState(() => ({
    pageIndex: defaultPage - 1,
    pageSize: defaultLimit,
  }));

  const [sorting, setSorting] = useState<SortingState>([
    { id: defaultSortBy, desc: defaultSortDirection === SortDirection.DESC },
  ]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    Array.from(newParams.entries()).forEach(([key, value]) => {
      if (!value) {
        newParams.delete(key);
      }
    });

    const newPage = parseInt(newParams.get('page') || `${defaultPage}`, 10);
    const newSize = parseInt(newParams.get('limit') || `${defaultLimit}`, 10);
    setPagination({ pageIndex: newPage - 1, pageSize: newSize });

    const sortBy = newParams.get('sortBy') || defaultSortBy;
    const sortDirection =
      newParams.get('sortDirection') || defaultSortDirection;
    setSorting([{ id: sortBy, desc: sortDirection === SortDirection.DESC }]);
  }, [
    searchParams,
    defaultPage,
    defaultLimit,
    defaultSortBy,
    defaultSortDirection,
  ]);

  const updateUrlParams = useCallback(
    (updates: IQueryParamProps | IQueryParamProps[]) => {
      const newParams = new URLSearchParams(searchParams.toString());

      if (Array.isArray(updates)) {
        updates.forEach(({ queryKey, queryValue, deleteOnKeyChange }) => {
          if (queryValue) {
            newParams.set(queryKey, queryValue);
          } else if (deleteOnKeyChange) {
            newParams.delete(queryKey);
          }
        });
      } else {
        const { queryKey, queryValue, deleteOnKeyChange } = updates;
        if (queryValue) {
          newParams.set(queryKey, queryValue);
        } else if (deleteOnKeyChange) {
          newParams.delete(queryKey);
        }
      }

      const newUrl = `${pathname}?${newParams.toString()}`;

      replace(newUrl, { scroll: false });
    },
    [pathname, replace, searchParams]
  );

  const onPaginationChange = useCallback(
    (updater: Updater<PaginationState, PaginationState>) => {
      const newPagination =
        typeof updater === 'function' ? updater(pagination) : updater;

      updateUrlParams([
        {
          queryKey: 'page',
          queryValue: `${newPagination.pageIndex + 1}`,
        },
        // {
        //   queryKey: 'limit',
        //   queryValue: `${newPagination.pageSize}`,
        // },
      ]);
    },
    [pagination, updateUrlParams]
  );

  const onSortingChange = useCallback(
    (updater: Updater<SortingState, SortingState>) => {
      const newSorting =
        typeof updater === 'function' ? updater(sorting) : updater;

      const { id, desc } = newSorting[0];

      updateUrlParams([
        {
          queryKey: 'sortBy',
          queryValue: id,
        },
        {
          queryKey: 'sortDirection',
          queryValue: desc ? SortDirection.DESC : SortDirection.ASC,
        },
      ]);
    },
    [sorting, updateUrlParams]
  );

  return {
    pagination,
    sorting,
    onPaginationChange,
    onSortingChange,
  };
};

export default useTableManagement;
