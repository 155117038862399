import { useSearchParams } from 'next/navigation';

import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from '@shared/constants/pagination';
import { QUERY_PARAM } from '@shared/constants/query';

const usePaginationParams = (defaultPageSize: number = DEFAULT_PAGE_SIZE) => {
  const searchParams = useSearchParams();

  const currentPage = searchParams.get(QUERY_PARAM.PAGE) || DEFAULT_PAGE_NUMBER;
  const pageSize = searchParams.get(QUERY_PARAM.PAGE_SIZE) || defaultPageSize;

  return {
    currentPage: +currentPage,
    pageSize: +pageSize,
  };
};

export default usePaginationParams;
