import {
  ReadonlyURLSearchParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useCallback, useState } from 'react';

import { IFetchParams, SortDirection } from '@interfaces/queryParams';
import { Updater } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/constants';

interface IQueryParamProps {
  queryKey: string;
  queryValue: string;
  shallow?: boolean;
  deleteOnKeyChange?: boolean;
}

interface IUseTableManagement {
  defaultPage?: number;
  defaultLimit?: number;
  defaultSortBy?: string;
  defaultSortDirection?: SortDirection;
}

const getFetchParams = (
  sort: SortingState,
  pagination: { pageIndex: number; pageSize: number }
) => {
  const baseSort = sort[0];
  const fetchParams: IFetchParams = {
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  };

  if (baseSort.id) {
    fetchParams.sortBy = baseSort.id;
    fetchParams.sortDirection = baseSort.desc
      ? SortDirection.DESC
      : SortDirection.ASC;
  }
  return fetchParams;
};

const getDefaultPagination = (
  searchParams: ReadonlyURLSearchParams,
  defaultPage: number,
  defaultLimit: number
) => {
  const page = searchParams.get(QUERY_PARAM.PAGE);
  const pageSize = searchParams.get('limit');
  const _pageSize = parseInt(pageSize || '10');
  const _page = parseInt(page || '1');
  if (!Number.isNaN(_page) && !Number.isNaN(_pageSize)) {
    return { pageIndex: _page - 1, pageSize: _pageSize };
  }

  return { pageIndex: defaultPage - 1, pageSize: defaultLimit };
};

const getDefaultSort = (
  searchParams: ReadonlyURLSearchParams,
  defaultSortBy: string,
  defaultSortDirection: string
) => {
  const id = searchParams.get(QUERY_PARAM.SORT_BY);
  const direction = searchParams.get(QUERY_PARAM.SORT_DIRECTION);
  if (id && direction) {
    return { id, desc: direction === SortDirection.DESC };
  }
  return {
    id: searchParams.get(QUERY_PARAM.SORT_BY) || defaultSortBy,
    desc: defaultSortDirection === SortDirection.DESC,
  };
};

const useTableManagementClient = ({
  defaultPage = 1,
  defaultLimit = 10,
  defaultSortBy = '',
  defaultSortDirection = SortDirection.DESC,
}: IUseTableManagement = {}) => {
  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();

  const [pagination, setPagination] = useState(() =>
    getDefaultPagination(searchParams, defaultPage, defaultLimit)
  );

  const [sorting, setSorting] = useState<SortingState>([
    getDefaultSort(searchParams, defaultSortBy, defaultSortDirection),
  ]);

  const updateUrlParams = useCallback(
    (updates: IQueryParamProps | IQueryParamProps[]) => {
      const newParams = new URLSearchParams(searchParams.toString());

      if (Array.isArray(updates)) {
        updates.forEach(({ queryKey, queryValue, deleteOnKeyChange }) => {
          if (queryValue) {
            newParams.set(queryKey, queryValue);
          } else if (deleteOnKeyChange) {
            newParams.delete(queryKey);
          }
        });
      } else {
        const { queryKey, queryValue, deleteOnKeyChange } = updates;
        if (queryValue) {
          newParams.set(queryKey, queryValue);
        } else if (deleteOnKeyChange) {
          newParams.delete(queryKey);
        }
      }

      const newUrl = `${pathname}?${newParams.toString()}`;

      replace(newUrl, { scroll: false });
    },
    [pathname, replace, searchParams]
  );

  const onPaginationChange = useCallback(
    (updater: Updater<PaginationState, PaginationState>) => {
      const newPagination =
        typeof updater === 'function' ? updater(pagination) : updater;
      setPagination(newPagination);
      updateUrlParams([
        {
          queryKey: QUERY_PARAM.PAGE,
          queryValue: `${newPagination.pageIndex + 1}`,
        },
        // {
        //   queryKey: 'limit',
        //   queryValue: `${newPagination.pageSize}`,
        // },
      ]);
    },
    [pagination, updateUrlParams]
  );

  const onSortingChange = useCallback(
    (updater: Updater<SortingState, SortingState>) => {
      const newSorting =
        typeof updater === 'function' ? updater(sorting) : updater;

      const { id, desc } = newSorting[0];

      setSorting(newSorting);

      updateUrlParams([
        {
          queryKey: QUERY_PARAM.SORT_BY,
          queryValue: id,
        },
        {
          queryKey: QUERY_PARAM.SORT_DIRECTION,
          queryValue: desc ? SortDirection.DESC : SortDirection.ASC,
        },
      ]);
    },
    [sorting, updateUrlParams]
  );

  const resetPaginationAndSorting = useCallback(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([{ id: '', desc: true }]);
  }, []);

  return {
    pagination,
    sorting,
    onPaginationChange,
    onSortingChange,
    fetchParams: getFetchParams(sorting, pagination),
    resetPaginationAndSorting,
  };
};

export default useTableManagementClient;
