export const NOW_PAYMENTS_BASE_URL = 'https://nowpayments.io';

export enum CryptoCurrencyID {
  AITECH = 'AITECH',
  USDAI = 'USDAI',
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}
