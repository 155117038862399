export enum QUERY_PARAM {
  BLOCKCHAIN = 'blockchain',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH = 'search',
  SEARCH_QUERY = 'searchQuery',
  SORT_BY = 'sortBy',
  PRODUCT_TYPE_IDS = 'productTypeIds',
  CATEGORY = 'category',
  TAGS = 'tags',
  IS_FREE_TRIAL = 'isFreeTrial',
  IS_DISABLED = 'isDisabled',
  KEYWORD = 'keyword',
  SORT_DIRECTION = 'sortDirection',
  EKYC_STATUS = 'ekycStatus',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  STATUS = 'status',
  PRICING_TYPE = 'pricingType',
  SUBMISSION_STATUSES = 'submissionStatuses',
}

export const DEFAULT_QUERY_VALUE = {
  SEARCH: '',
  PAGE: 1,
};
