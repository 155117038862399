import { useQuery } from '@tanstack/react-query';

import { USER_ME } from '@shared/constants/queryKeys';

import { userAuthApi } from '../userAuthApi';

const useCurrentUser = () =>
  useQuery({
    queryFn: () => userAuthApi.client.getMe(),
    queryKey: USER_ME,
  });

export default useCurrentUser;
