import { useEffect } from 'react';

import { QueryKey, Updater, useQueryClient } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';

interface UsePaginationCorrection {
  dataLength: number;
  meta: {
    currentPage: number;
    totalPages: number;
  };
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  onPaginationChange: (
    updater: Updater<PaginationState, PaginationState>
  ) => void;
  queryKeyInvalidate?: QueryKey;
  queryKeyRemove?: QueryKey;
}

const usePaginationCorrection = ({
  dataLength,
  meta,
  pagination,
  onPaginationChange,
  queryKeyInvalidate,
  queryKeyRemove,
}: UsePaginationCorrection) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (dataLength === 0 && meta.currentPage > meta.totalPages) {
      queryClient.invalidateQueries({
        queryKey: queryKeyInvalidate,
      });
      queryClient.removeQueries({
        queryKey: queryKeyRemove,
      });
      onPaginationChange({
        pageIndex: pagination.pageIndex - 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [
    dataLength,
    meta,
    onPaginationChange,
    pagination,
    queryClient,
    queryKeyInvalidate,
    queryKeyRemove,
  ]);
};

export default usePaginationCorrection;
