import {
  AuthResponse,
  ConfirmEmailData,
  ConfirmEmailResponse,
  GetSigninMessageParams,
  GetSigninMessageResponse,
  IRequestConfirmEmailResponse,
  LoginWithWalletData,
  RegisterWitWalletData,
  RequestOtpCodeData,
  UserGetMeResponse,
} from '@interfaces/auth';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import { withAuthenticatedServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';
import { generateUrlParams } from '@shared/utils/urlString';

const loginWithWallet =
  (request: ICallableRequestBuilder<AuthResponse>) =>
  (data: LoginWithWalletData): Promise<AuthResponse> => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/auth/login`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const registerWithWallet =
  (request: ICallableRequestBuilder<AuthResponse>) =>
  (data: RegisterWitWalletData): Promise<AuthResponse> => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/auth/register`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const confirmEmail =
  (request: ICallableRequestBuilder<ConfirmEmailResponse>) =>
  (data: ConfirmEmailData): Promise<ConfirmEmailResponse> => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/auth/email/confirm`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const requestOtpCode =
  (request: ICallableRequestBuilder<IRequestConfirmEmailResponse>) =>
  (data: RequestOtpCodeData): Promise<IRequestConfirmEmailResponse> => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/auth/email/request_confirm_email`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const getSigninMessage =
  (request: ICallableRequestBuilder<GetSigninMessageResponse>) =>
  async (params: GetSigninMessageParams) => {
    const paramString = generateUrlParams(params);
    return request.call(
      `${config.userApiUrl}/api/v1/user/auth/signin-message?${paramString}`
    );
  };

const getMe =
  (request: ICallableRequestBuilder<UserGetMeResponse>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/user/auth/me`);
  };

export const userAuthApi = {
  client: {
    loginWithWallet: withClientRequest(loginWithWallet),
    registerWithWallet: withClientRequest(registerWithWallet),
    getSigninMessage: withClientRequest(getSigninMessage),
    confirmEmail: withClientRequest(confirmEmail),
    requestOtpCode: withClientRequest(requestOtpCode),
    getMe: withAuthenticatedClientRequest(getMe),
  },
  server: {
    getMe: async () => {
      try {
        const response = await withAuthenticatedServerRequest(getMe)();
        return response;
      } catch (e) {
        return null;
      }
    },
  },
};
