export const generateUrlParams = (params: unknown): string => {
  if (!params) {
    return '';
  }

  const formattedParams: [string, string][] = [];

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        formattedParams.push([key, val]);
      });
    } else {
      formattedParams.push([key, value]);
    }
  });

  return new URLSearchParams(formattedParams).toString();
};
