import { useCallback, useEffect, useState } from 'react';

import { copyToClipboard } from '@shared/utils';

const useCopyText = (text?: string) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyAddressHandler = useCallback(async () => {
    if (!text) return;
    const isSuccessful = await copyToClipboard(text);
    setIsCopied(isSuccessful);
  }, [text]);

  useEffect(() => {
    if (!isCopied) return;

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  return { isCopied, copyAddressHandler };
};

export default useCopyText;
