import { useState } from 'react';

import { UpdatePage } from '@interfaces';

import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
} from '@shared/constants/pagination';
import { QUERY_PARAM } from '@shared/constants/query';
import { useUpsertQueryParams } from '@shared/hooks';

import usePaginationParams from './usePaginationParams';

const usePagination = (
  itemsPerPage: number[] = DEFAULT_ITEMS_PER_PAGE,
  options: {
    disableQueryParams?: boolean;
    defaultItemsPerPage?: number;
  } = { disableQueryParams: false }
) => {
  const defaultPageSize = options.defaultItemsPerPage
    ? options.defaultItemsPerPage
    : itemsPerPage[0];

  const { currentPage, pageSize: pageSizeQueryParam } =
    usePaginationParams(defaultPageSize);

  const { upsertQueryParams } = useUpsertQueryParams();

  const itemsPerPageValue = itemsPerPage.find(
    (item) => item === pageSizeQueryParam
  );

  const [page, setPage] = useState(currentPage || DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(
    itemsPerPageValue || defaultPageSize
  );

  const updatePage = ({
    newPage = 1,
    disableQueryParams = false,
  }: UpdatePage) => {
    setPage(newPage);
    if (!options.disableQueryParams && !disableQueryParams) {
      upsertQueryParams([
        {
          key: QUERY_PARAM.PAGE,
          value: newPage === DEFAULT_PAGE_NUMBER ? '' : newPage,
        },
      ]);
    }
  };

  const updatePageSize = (newSize: number) => {
    setPageSize(newSize);
    setPage(DEFAULT_PAGE_NUMBER);

    if (!options.disableQueryParams) {
      upsertQueryParams([
        {
          key: QUERY_PARAM.PAGE_SIZE,
          value: newSize === defaultPageSize ? '' : newSize,
        },
      ]);
    }
  };

  return { page, pageSize, updatePage, updatePageSize };
};

export default usePagination;
