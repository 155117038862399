import useCurrentPublisher from '@api/publisher/auth/queries/useCurrentPublisher';
import useCurrentUser from '@api/user/auth/queries/useCurrentUser';

// Should be named something broader like useAuthStatus
const useForumAuthStatus = () => {
  const { data: currentUser } = useCurrentUser();
  const { data: currentPublisher } = useCurrentPublisher();

  const isUser = !!currentUser?.data && !currentPublisher?.data;
  const isPublisher = !!currentPublisher?.data && !currentUser?.data;

  const isBothRoles = !!currentUser?.data && !!currentPublisher?.data;
  const isLoggedIn = !!currentUser?.data || !!currentPublisher?.data;

  return {
    isUser,
    isPublisher,
    isBothRoles,
    isLoggedIn,
  };
};

export default useForumAuthStatus;
