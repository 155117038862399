import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export interface IUpdateWithQueryParamsProps {
  queryKey: string;
  queryValue?: string | number;
  deleteOnKeyChange?: boolean;
}

const useUpdateUrlWithQueryParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const updateUrlWithQueryParams = (
    queryParams: IUpdateWithQueryParamsProps | IUpdateWithQueryParamsProps[]
  ) => {
    const params = new URLSearchParams(searchParams);

    const queryParamsArray = Array.isArray(queryParams)
      ? queryParams
      : [queryParams];

    queryParamsArray.forEach(({ queryKey, queryValue, deleteOnKeyChange }) => {
      if (deleteOnKeyChange) {
        params.delete(queryKey);
      } else if (queryValue !== undefined) {
        params.set(queryKey, queryValue.toString());
      }
    });

    router.replace(`${pathname}?${params.toString()}`, { scroll: false });
  };

  return updateUrlWithQueryParams;
};

export default useUpdateUrlWithQueryParams;
