export enum ProductVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
  NONE = 'none',
}

export enum SubmittedProductStatus {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum SubmittedProductTable {
  PENDING = 'pending',
  REJECTED = 'rejected',
}
