import { useEffect, useRef, useState } from 'react';

import '@shared/hooks';

/**
 * Custom hook that handles text overflow by determining if the text requires a "Read More" option
 * and toggling the visibility of the additional text.
 * When using it within a SSR component, you should before check if the component is mounted using useMount hook.
 * That's because the ref is not available during the first render.
 *
 *
 * @param text - The text to be displayed.
 * @param maxLines - The maximum number of lines to display before showing the "Read More" option.
 * @returns An object containing the following properties:
 *   - requiresReadMore: A boolean indicating whether the text requires a "Read More" option.
 *   - isMoreShown: A boolean indicating whether the additional text is currently visible.
 *   - paraRef: A ref to the paragraph element that contains the text.
 *   - toggleReadMore: A function to toggle the visibility of the additional text.
 *   - paragraphStyle: A CSSProperties object to apply to the paragraph element.
 */
export const useTextOverflowHandler = (text: string, maxLines: number) => {
  const [requiresReadMore, setRequiresReadMore] = useState(false);
  const [isMoreShown, setIsMoreShown] = useState(false);

  const paraRef = useRef<HTMLParagraphElement>(null); // Can be expanded to handle different element types

  useEffect(() => {
    const paraRefValue = paraRef.current;
    const calculateLineHeight = () => {
      if (!paraRef.current) return;

      const lineHeight = parseFloat(
        getComputedStyle(paraRef.current).lineHeight
      );

      const isMoreThanMaxLines =
        paraRef.current.scrollHeight > lineHeight * maxLines ||
        paraRef.current.scrollWidth > paraRef.current.offsetWidth;

      setRequiresReadMore(isMoreThanMaxLines);
    };
    const observer = new ResizeObserver(calculateLineHeight);
    if (paraRefValue) {
      observer.observe(paraRefValue);
    }
    return () => {
      if (paraRefValue) {
        observer.unobserve(paraRefValue);
      }
    };
  }, [text, maxLines]);

  const paragraphStyle: React.CSSProperties = !isMoreShown
    ? {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        wordBreak: 'break-word',
      }
    : { WebkitLineClamp: 'none' };

  const toggleReadMore = () => {
    setIsMoreShown(!isMoreShown);

    // Scroll back to the thread start when collapsing the content
    if (isMoreShown && paraRef.current) {
      paraRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return {
    requiresReadMore,
    isMoreShown,
    paraRef,
    toggleReadMore,
    paragraphStyle,
  };
};
