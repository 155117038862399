import { useEffect } from 'react';

import { disableScrollOnBody } from '@shared/utils';

const useToggleScrollOnBody = (isOpen: boolean, isMobile = false) => {
  useEffect(() => {
    disableScrollOnBody(isOpen, isMobile);

    return () => {
      if (isOpen) disableScrollOnBody(true, isMobile);
    };
  }, [isMobile, isOpen]);
};

export default useToggleScrollOnBody;
