export enum E_THREAD_OR_COMMENT_REPORT_REASON {
  VIOLENT_OR_REPULSIVE_CONTENT = 'violent_or_repulsive_content',
  HATEFUL_OR_ABUSIVE_CONTENT = 'hateful_or_abusive_content',
  HARASSMENT_OR_BULLYING = 'harassment_or_bullying',
  SPAM_OR_MISLEADING = 'Spam_or_misleading',
  LEGAL_ISSUE = 'legal_issue',
}

export enum E_THREAD_STATUS {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}
