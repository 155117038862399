import { useAccount, useDisconnect } from 'wagmi';

import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';

const useConnectWallet = () => {
  const { open } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const { disconnectAsync } = useDisconnect();
  const { address, isReconnecting } = useAccount();

  const handleConnect = async () => {
    // AM-1185
    // - only able to click if not reconnecting wallet
    // - if edge case happens that address exists but login modal didn't open, on click first disconnect the connected wallet, and then open web3modal
    !!address && (await disconnectAsync());
    await disconnectAsync();
    open();
  };

  return {
    handleConnect,
    isReconnecting,
    isOpen,
  };
};

export default useConnectWallet;
