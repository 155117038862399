import { useQuery } from '@tanstack/react-query';

import { PUBLISHER_ME } from '@shared/constants/queryKeys';

import { publisherAuthApi } from '../publisherAuthApi';

const useCurrentPublisher = () =>
  useQuery({
    queryFn: () => publisherAuthApi.client.getPublisherMe(),
    queryKey: PUBLISHER_ME,
  });

export default useCurrentPublisher;
