import {
  AuthForgotData,
  AuthResetPasswordData,
  AuthResetPasswordResponseData,
  ConfirmEmailResponse,
  IBasicResponse,
  IChangePasswordData,
  IPublisherGetDocumentUrlRequest,
  PublisherConfirmEmailData,
  PublisherGetDocumentUrlResponse,
  PublisherGetMeResponse,
  PublisherLoginData,
  PublisherLoginOtpData,
  PublisherLoginOtpResponseData,
  PublisherLoginResponse,
  PublisherRegisterData,
  PublisherRegisterRequestOtpCodeData,
  PublisherRegisterResponseData,
  ResponseWrapper,
} from '@interfaces';

import { withAuthenticatedPublisherClientRequest } from '@api/requestBuilder/client/withAuthenticatedPublisherClientRequest';
import { withClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { withAuthenticatedPublisherServerRequest } from '@api/requestBuilder/server/withAuthenticatedPublisherServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

import { publisherAuthApiRoutes } from '../publisherApi.routes';

const publisherLoginOtp =
  (request: ICallableRequestBuilder<PublisherLoginOtpResponseData>) =>
  (data: PublisherLoginOtpData): Promise<PublisherLoginOtpResponseData> => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/publisher/auth/email/login_otp`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const publisherLogin =
  (request: ICallableRequestBuilder<PublisherLoginResponse>) =>
  (data: PublisherLoginData): Promise<PublisherLoginResponse> => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/publisher/auth/email/login`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const publisherRegister =
  (request: ICallableRequestBuilder<PublisherRegisterResponseData>) =>
  (data: PublisherRegisterData): Promise<PublisherRegisterResponseData> => {
    return request.call(publisherAuthApiRoutes.publisherRegister, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const publisherRegisterConfirmEmail =
  (request: ICallableRequestBuilder<ConfirmEmailResponse>) =>
  (data: PublisherConfirmEmailData): Promise<ConfirmEmailResponse> => {
    return request.call(
      publisherAuthApiRoutes.publisherRegisterConfirmEmail,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const publisherForgot =
  (request: ICallableRequestBuilder<AuthResetPasswordResponseData>) =>
  (data: AuthForgotData): Promise<AuthResetPasswordResponseData> => {
    return request.call(publisherAuthApiRoutes.publisherForgot, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

const publisherResetPassword =
  (request: ICallableRequestBuilder<AuthResetPasswordResponseData>) =>
  (data: AuthResetPasswordData): Promise<AuthResetPasswordResponseData> => {
    return request.call(
      publisherAuthApiRoutes.publisherResetPassword,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const publisherRegisterRequestOtpCode =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
  (data: PublisherRegisterRequestOtpCodeData): Promise<ResponseWrapper> => {
    return request.call(
      publisherAuthApiRoutes.publisherRegisterRequestOtpCode,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

const getPublisherMe =
  (request: ICallableRequestBuilder<PublisherGetMeResponse>) => async () => {
    return request.call(publisherAuthApiRoutes.publisherMe);
  };

const publisherGetDocumentUrl =
  (request: ICallableRequestBuilder<PublisherGetDocumentUrlResponse>) =>
  async (data: IPublisherGetDocumentUrlRequest) => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/publisher/kyc/proof-of-identity-business-document
  `,
      (init) => ({
        method: 'PUT',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        ...init,
        body: JSON.stringify(data),
      })
    );
  };

const publisherResetDocumentUrl =
  (request: ICallableRequestBuilder<PublisherGetDocumentUrlResponse>) =>
  async () => {
    return request.call(
      `${config.publisherApiUrl}/api/v1/publisher/kyc/proof-of-identity-business-document/reset
  `,
      (init) => ({
        method: 'PUT',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        ...init,
      })
    );
  };

const publisherChangePassword =
  (request: ICallableRequestBuilder<IBasicResponse>) =>
  async (data: IChangePasswordData) => {
    return request.call(
      publisherAuthApiRoutes.publisherChangePassword,
      (init) => ({
        method: 'POST',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  };

export const publisherAuthApi = {
  client: {
    loginOtp: withClientRequest(publisherLoginOtp),
    login: withClientRequest(publisherLogin),
    publisherRegister: withClientRequest(publisherRegister),
    publisherRegisterConfirmEmail: withClientRequest(
      publisherRegisterConfirmEmail
    ),
    publisherRegisterRequestOtpCode: withClientRequest(
      publisherRegisterRequestOtpCode
    ),
    publisherForgot: withClientRequest(publisherForgot),
    publisherResetPassword: withClientRequest(publisherResetPassword),
    getPublisherMe: withAuthenticatedPublisherClientRequest(getPublisherMe),
    publisherGetDocumentUrl: withAuthenticatedPublisherClientRequest(
      publisherGetDocumentUrl
    ),
    publisherResetDocumentUrl: withAuthenticatedPublisherClientRequest(
      publisherResetDocumentUrl
    ),
    publisherChangePassword: withAuthenticatedPublisherClientRequest(
      publisherChangePassword
    ),
  },
  server: {
    getPublisherMe: async () => {
      try {
        const response =
          await withAuthenticatedPublisherServerRequest(getPublisherMe)();
        return response;
      } catch (e) {
        return null;
      }
    },
  },
};
