import { useState } from 'react';

import useDebounce from './useDebounce';

/**
 * A custom hook that handles delayed status updates. Used to prevent multiple API calls when a status is updated multiple times in quick succession.
 *
 * @param fn - The function to be executed after the delay.
 * @param initialStatus - The initial status value.
 * @param initialStatusCount - The initial status count value.
 * @param delay - The delay in milliseconds before executing the function.
 * @returns An object containing the status and count values, as well as a function to handle the status update.
 */
export const useDebounceStatusChange = (
  fn: () => void,
  {
    initialStatus,
    initialStatusCount = null,
  }: { initialStatus: boolean; initialStatusCount?: number | null },
  delay = 350
) => {
  const [isLocalStatus, setIsLocalStatus] = useState(initialStatus);
  const [localStatusCount, setLocalStatusCount] = useState(initialStatusCount);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debouncedStatusFn = useDebounce((hasStatusChanged: boolean) => {
    if (!hasStatusChanged) return;

    fn();
  }, delay);

  const handleStatusUpdate = () => {
    const updatedLikeStatus = !isLocalStatus;

    setIsLocalStatus(updatedLikeStatus);

    if (localStatusCount !== null) {
      setLocalStatusCount(
        isLocalStatus ? localStatusCount - 1 : localStatusCount + 1
      );
    }

    const hasStatusChanged = initialStatus !== updatedLikeStatus;

    debouncedStatusFn(hasStatusChanged);
  };

  return {
    isLocalStatus,
    localStatusCount,
    handleStatusUpdate,
  };
};
