import { useEffect, useState } from 'react';

import { EScrollDirection } from '@shared/enums/hooks';

/**
/**
 * A custom React hook that tracks the direction of the user's scroll on the webpage.
 *
 * @param {number} threshold - The minimum amount of pixels the user needs to scroll before the direction is updated.
 * @returns {EScrollDirection} - The current scroll direction. Returns EScrollDirection.UP if the user has scrolled up more than the threshold, EScrollDirection.DOWN if the user has scrolled down more than the threshold, and EScrollDirection.NONE if the user has not scrolled or the scroll amount is less than the threshold.
 *
 * @example
 * import useScrollDirection, { EScrollDirection } from './useScrollDirection';
 *
 * const MyComponent = () => {
 *   const scrollDirection = useScrollDirection(100);
 *
 *   return (
 *     <div>
 *       {scrollDirection === EScrollDirection.UP && <div>Scrolling Up</div>}
 *       {scrollDirection === EScrollDirection.DOWN && <div>Scrolling Down</div>}
 *     </div>
 *   );
 * };
 */
const useScrollDirection = (threshold: number): EScrollDirection => {
  const [scrollDirection, setScrollDirection] = useState<EScrollDirection>(
    EScrollDirection.NONE
  );

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }
      setScrollDirection(
        scrollY > lastScrollY ? EScrollDirection.DOWN : EScrollDirection.UP
      );
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const requestTick = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
    };

    window.addEventListener('scroll', requestTick);
    return () => window.removeEventListener('scroll', requestTick);
  }, [threshold]);

  return scrollDirection;
};

export default useScrollDirection;
