import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

interface IProps {
  keys: string[];
}

const useClearSearchParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const clearSearchParams = useCallback(
    ({ keys }: IProps) => {
      const params = new URLSearchParams(searchParams.toString());
      keys.map((item) => {
        params.delete(item);
      });

      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
    },
    [pathname, router, searchParams]
  );

  return clearSearchParams;
};

export default useClearSearchParams;
