import { SaleStatus } from '@interfaces/admin/sales';

import { PRICING_MODEL_TYPE } from '@features/profile/tabs/tabContent/purchasedApps/types';

export type SortType = 'name' | 'createdAt' | ReportedCommentsSortKeys;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IFetchParams {
  [key: string]: string | number | (string | number)[] | undefined;
  keyword?: string;
  page?: number;
  limit?: number;
  sortBy?: SortType | string;
  sortDirection?: SortDirection;
  productId?: number;
  submissionId?: string;
  status?: string | SaleStatus;
  pricingType?: PRICING_MODEL_TYPE;
  ['filter.status']?: string; // string combination of multiple RefundRequestStatus
}

export interface IPageSearchParams {
  searchParams: IFetchParams;
}

type ReportedCommentsSortKeys = 'reportCount';
