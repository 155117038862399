export const DEFAULT_PAGE_SIZE = 5;

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_PAGINATION_STATE = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const DEFAULT_ITEMS_PER_PAGE = [5, 10, 20];
