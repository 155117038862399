import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

type UseUnsavedChangesParams = {
  isDirty: boolean;
  onStay?: () => void;
  onLeave?: () => void;
};

const useUnsavedChanges = ({
  isDirty,
  onStay,
  onLeave,
}: UseUnsavedChangesParams) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const confirmationFn = useRef<() => void>(() => {});
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.history.pushState(null, document.title, window.location.href);
    }
  }, []);

  useEffect(() => {
    let lastClickTime = 0;
    const debounceTime = 300;

    const handleClick = (event: MouseEvent) => {
      const now = Date.now();
      if (now - lastClickTime < debounceTime) return;
      lastClickTime = now;

      let target = event.target as HTMLElement;
      while (target && target.tagName !== 'A') {
        target = target.parentElement as HTMLElement;
      }

      if (target && target.tagName === 'A') {
        const anchorTarget = target as HTMLAnchorElement;

        if (
          isDirty &&
          typeof window !== 'undefined' &&
          anchorTarget.href !== window.location.href
        ) {
          window.history.pushState(null, document.title, window.location.href);
          confirmationFn.current = () => {
            window.location.href = anchorTarget.href;
          };

          setShowUnsavedChangesModal(true);
          event.preventDefault();
        }
      }
    };

    const handlePopState = () => {
      if (isDirty && typeof window !== 'undefined') {
        window.history.pushState(null, document.title, window.location.href);

        confirmationFn.current = () => {
          router.back();
        };

        setShowUnsavedChangesModal(true);
      } else {
        if (typeof window !== 'undefined') {
          router.back();
        }
      }
    };

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('click', handleClick);
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', handleClick);
        window.removeEventListener('popstate', handlePopState);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [isDirty, router]);

  const confirmNavigation = () => {
    setShowUnsavedChangesModal(false);
    if (confirmationFn.current) {
      confirmationFn.current();
    }
    onLeave?.();
  };

  const cancelNavigation = () => {
    setShowUnsavedChangesModal(false);
    confirmationFn.current = () => {};
    onStay?.();
  };

  const onUnsavedChangesClose = () => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
      return;
    }

    onLeave?.();
  };

  return {
    showUnsavedChangesModal,
    confirmNavigation,
    cancelNavigation,
    onUnsavedChangesClose,
  };
};

export default useUnsavedChanges;
