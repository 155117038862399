import { useQueryClient } from '@tanstack/react-query';

import { FORUM_LOGIN_MODAL } from '@shared/constants/queryKeys';

import useForumAuthStatus from './useForumAuthStatus';

const useWithForumAuth = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (arg: any) => void
) => {
  const { isLoggedIn } = useForumAuthStatus();
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (arg?: any) => {
    if (!isLoggedIn) {
      queryClient.setQueryData(FORUM_LOGIN_MODAL, true);
      return;
    }
    fn(arg);
  };
};

export default useWithForumAuth;
