import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

interface IProps {
  queryName?: string;
  queryValues: object;
}

const useUpdateUrlWithObjectQueryParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const updateUrlWithQueryParams = useCallback(
    ({ queryName, queryValues }: IProps) => {
      const params = new URLSearchParams(searchParams.toString());

      if (Array.isArray(queryValues)) {
        if (queryName) {
          queryValues.length
            ? params.set(queryName, queryValues.join(','))
            : params.delete(queryName);
        }
      } else {
        Object.entries(queryValues).forEach(([key, value]) => {
          value.length ? params.set(key, value) : params.delete(key);
        });
      }

      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
    },
    [pathname, router, searchParams]
  );

  return updateUrlWithQueryParams;
};

export default useUpdateUrlWithObjectQueryParams;
