import { IAdminRejectOption } from '@interfaces/admin';

import { E_REASON_OPTIONS } from '@shared/enums';

export const REASON_OPTIONS: IAdminRejectOption[] = [
  {
    label: 'Violent or repulsive content',
    value: E_REASON_OPTIONS.VIOLENT_OR_REPULSIVE_CONTENT,
  },
  {
    label: 'Hateful or abusive content',
    value: E_REASON_OPTIONS.HATEFUL_OR_ABUSIVE_CONTENT,
  },
  { label: 'Spam or misleading', value: E_REASON_OPTIONS.SPAM_OR_MISLEADING },
  { label: 'Legal issue', value: E_REASON_OPTIONS.LEGAL_ISSUE },
  { label: 'Others', value: E_REASON_OPTIONS.OTHER },
];
